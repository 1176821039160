

































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Dongtaishuzu from "./components/shuzu-dongtai.vue";
import Qitayongyao from "./components/qitayongyao.vue";
import Zhongyaoyinpian from "./components/zhongyaoyinpian.vue";
import Textarea from "./components/textarea.vue";
import Wenben from "./components/wenben.vue";
import Zifuchuan from "./components/zifuchuan.vue";
import DsImage from "./components/ds-image.vue";
import Wenjian from "./components/wenjian.vue";
import Shipin from "./components/shipin.vue";
import TitleZhu from "./components/title-zhu.vue";
import TitleDuanluo from "./components/title-duanluo.vue";
import Radio from "./components/radio.vue";
import RadioInput from "./components/radio-input.vue";
import RadioScore from "./components/radio-score.vue";
import RadioSf36score from "./components/radio-sf36score.vue";
import Checkbox from "./components/checkbox.vue";
import CheckboxInput from "./components/checkbox-input.vue";
import SingleTime from "./components/single-time.vue";
import Jifentiao from "./components/jifentiao.vue";
import Select from "./components/select-ds.vue";
import SelectMulu from "./components/select-mulu.vue";
import SearchDuoxuan from "./components/search-duoxuan.vue";
import Number from "./components/number.vue";
import NumberUnit from "./components/number-unit.vue";
import Jianyanzu from "./components/jianyanzu.vue";
import Jiatingzhuzhi from "./components/jiatingzhuzhi.vue";
import DsTable from "./components/ds-table.vue";
import Yatongdian from "./components/yatongdian.vue";
import YaozhuiYatongdianLeft from "./components/yaozhui-yatongdian-left.vue";
import YaozhuiYatongdianRight from "./components/yaozhui-yatongdian-right.vue";
import XiguanjieYatongdianLeft from "./components/xiguanjie-yatongdian-left.vue";
import XiguanjieYatongdianRight from "./components/xiguanjie-yatongdian-right.vue";
import ZhuyuanDetail from "./components/zhuyuan-detail.vue";
import XitongJibing from "./components/xitong-jibing.vue";
import Lianjie from "./components/lianjie.vue";
import { hideContent } from "./common";
@Component({
  components: {
    Dongtaishuzu,
    Qitayongyao,
    Zhongyaoyinpian,
    SearchDuoxuan,
    Textarea,
    Wenben,
    Zifuchuan,
    DsImage,
    Wenjian,
    Shipin,
    TitleZhu,
    TitleDuanluo,
    Radio,
    RadioInput,
    Checkbox,
    CheckboxInput,
    SingleTime,
    RadioScore,
    RadioSf36score,
    Jifentiao,
    Select,
    SelectMulu,
    Number,
    NumberUnit,
    Jianyanzu,
    Jiatingzhuzhi,
    DsTable,
    Yatongdian,
    YaozhuiYatongdianLeft,
    YaozhuiYatongdianRight,
    XiguanjieYatongdianLeft,
    XiguanjieYatongdianRight,
    ZhuyuanDetail,
    XitongJibing,
    Lianjie,
  },
})
export default class Name extends Vue {
  @Prop()
  private wholeTem: any; // 整个量表数组,用来做量表特殊处理，处理后的数据通过updateWholeTem传到父组件更新
  @Prop()
  private index: any;
  @Prop()
  private content: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private update(item: any) {
    const data = {
      index: this.index,
      data: item,
    };
    if (this.wholeTem) {
      let arr: any = JSON.parse(JSON.stringify(this.wholeTem));
      //计算BMI，量表顺序只能是身高，体重，BMI
      if (item.title === "身高") {
        const shengao = arr[this.index].value / 100;
        const tizhong = arr[this.index + 1].value;
        arr[this.index + 2].value = (tizhong / (shengao * shengao)).toFixed(2);
      } else if (item.title === "体重") {
        const shengao = arr[this.index - 1].value / 100;
        const tizhong = arr[this.index].value;
        arr[this.index + 1].value = (tizhong / (shengao * shengao)).toFixed(2);
      }
      arr[this.index] = item;
      this.$emit("updateWholeTem", arr);
    } else {
      this.$emit("update:content", data);
    }
  }
  private updateDialog(e: any) {
    this.$emit("updateDialog", e);
  }
  private changeHide(e: any) {
    if (this.wholeTem) {
      setTimeout(() => {
        const d = this.wholeTem;
        const result = hideContent(e, d);
        this.$emit("updateWholeTem", result);
      }, 500);
    } else {
      // 此处是为了兼容老代码
      this.$emit("changeHide", e);
    }
  }
  private delTimu(e: any) {
    this.$emit("delTimu", e);
  }
  private jishu() {
    // 这是广东民族医特有的
    this.$emit("jishu", this.index);
  }
  /**
   * @description 这是广东民族医特有的，纳排标准全选是或者全选否
   * @param e type(段落标题),key(),val(true/false),index
   */
  private selectAll(e: any) {
    const d = JSON.parse(JSON.stringify(this.wholeTem));
    if (
      e.type == "段落标题" &&
      d[e.index].title &&
      d[e.index].title.indexOf(e.key) !== -1
    ) {
      let IfEnding: any = false;
      d.forEach((ele: any, i: any) => {
        if (i > e.index) {
          if (ele.type == "单选" && e.val && !IfEnding) {
            ele.value = "是";
          } else if (ele.type == "单选" && !e.val && !IfEnding) {
            ele.value = "否";
          }
          // 出现下一个段落标题时结束
          if (ele.type == "段落标题") {
            IfEnding = true;
          }
        }
      });
      this.$emit("updateWholeTem", d);
    }
  }
}
