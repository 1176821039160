




























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetMulu, GetTerms } from "../request";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private cpart: any = "";
  private isOpen: boolean = false;
  private options: any = [];
  private mulu: any = [];
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valChange(e: any) {
    this.mulu.forEach((items: any) => {
      items.children.forEach((item: any) => {
        item.value = false;
        e.forEach((ele: any) => {
          if (item.id === ele.id) {
            item.value = true;
          }
        });
      });
    });
    this.valueChange(e);
  }
  private valueChange(e: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private remoteMethod(e: any) {
    this.fetchOptions(e);
  }
  /**
   * @description 搜索
   */
  private fetchOptions(ev: any) {
    const params: any = {
      params: {
        type: this.c.search,
        search: ev,
      },
    };
    GetTerms(this, params).then((data: any) => {
      this.content.value.forEach((element: any) => {
        const index = data.findIndex((val: any) => {
          return element.id === val.id;
        });
        if (index === -1) {
          data.push(element);
        }
      });
      this.options = data;
    });
  }
  /**
   * @description 获取目录信息
   */
  private getMulu() {
    GetMulu(this).then((res: any) => {
      this.mulu = res;
      this.cpart = this.mulu[0].name;
      // 循环选中值
      this.mulu.forEach((items: any) => {
        items.children.forEach((item: any) => {
          item.value = false;
          this.content.value.forEach((ele: any) => {
            if (item.id === ele.id) {
              item.value = true;
            }
          });
        });
      });
    });
  }
  private scrollEvent() {
    const parant = document.getElementById("scrollBox" + this.index);
    if (parant) {
      const scrollTop = parant.scrollTop;
      const parentTop = parant.offsetTop + 14;
      this.mulu.forEach((ele: any, i: any) => {
        if (i < this.mulu.length) {
          const cuEle = document.getElementById(this.index + "part" + i);
          const nextEle = document.getElementById(
            this.index + "part" + (i + 1)
          );
          if (cuEle && nextEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            const nextTop = nextEle.offsetTop - parentTop;
            if (scrollTop >= cuTop && scrollTop < nextTop) {
              this.cpart = ele.name;
            }
          }
        } else {
          const cuEle = document.getElementById(this.index + "part" + i);
          if (cuEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            if (scrollTop >= cuTop) {
              this.cpart = ele.name;
            }
          }
        }
      });
    }
  }
  /**
   * @description 切换左边导航
   */
  private changePart(item: any, i: any) {
    const parant: any = document.getElementById("scrollBox" + this.index);
    const children: any = document.getElementById(this.index + "part" + i);
    if (parant) {
      if (children) {
        const parentTop = parant.offsetTop;
        const childTop = children.offsetTop;
        parant.scrollTop = childTop - parentTop - 14;
      }
    }
    setTimeout(() => {
      this.cpart = item.name;
    }, 50);
  }
  private checkChange(e: any) {
    const val: any = [];
    this.mulu.forEach((items: any) => {
      items.children.forEach((ele: any) => {
        if (ele.value) {
          val.push(ele);
        }
      });
    });
    this.c.value = val;
    this.options = JSON.parse(JSON.stringify(val));
    this.valueChange(e);
  }
  private mounted() {
    this.options = JSON.parse(JSON.stringify(this.content.value));
    this.getMulu();
  }
}
