



































import { Component, Vue, Prop } from "vue-property-decorator";
const cityData = require("../../assets/pca-code.json");
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private cityOption: any;
  private get c() {
    const data = JSON.parse(JSON.stringify(this.content));
    return data;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  /**
   * @description 加载三级联动数据，加载缓存的验证码等待时间
   */
  private beforeMount() {
    this.cityOption = cityData;
  }
}
