





































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
import { clearVal } from "../common";
const TemplateTransfer = () => import("../template-transfer.vue");
@Component({
  components: {
    Title,
    SubTitle,
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private clickCheckbox(item: any) {
    if (!this.ifCompose && this.source != "dialog") {
      return;
    }
    if (!item.value) {
      item.content = "";
      if (item["options"] && item["options"].length > 0) {
        item["options"].forEach((ele: any) => {
          ele.value = false;
          ele.content = "";
        });
      }
      if (item.extra) {
        item.extra.forEach((d: any) => {
          clearVal(d);
        });
      }
    }
    this.valueChange();
  }
  private updateContent(e: any, index: any) {
    this.c.options[index]["extra"][e.index] = e.data;
    this.$emit("update", this.c);
  }
}
