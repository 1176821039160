











































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  /**
   * @description 获取store中的可编辑状态
   */
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get c() {
    if (!this.content.value["棘旁压痛"]) {
      this.content.value["棘旁压痛"] = {};
    }
    if (!this.content.value["棘上压痛"]) {
      this.content.value["棘上压痛"] = {};
    }
    return this.content;
  }
  private set c(val) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", val);
    } else {
      this.$emit("update", val);
    }
  }
  /**
   * @description content双向绑定
   */
  private contentChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.content);
    } else {
      this.$emit("update", this.content);
    }
  }
  private toggleValue(key: any, sub: any) {
    if (sub) {
      if (sub == "无压痛点") {
        const value = !this.c.value[key];
        this.c.value = {
          棘旁压痛: {},
          棘上压痛: {},
        };
        this.$set(this.c.value, key, value);
      } else {
        const value = !this.c.value[sub][key];
        this.$set(this.c.value[sub], key, value);
        this.$set(this.c.value, "无压痛点", false);
      }
    } else {
      const value = !this.c.value[key];
      this.$set(this.c.value, key, value);
      this.$set(this.c.value, "无压痛点", false);
    }
    this.$forceUpdate();
    // this.getText();
  }
}
