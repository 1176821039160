













































































import { Component, Vue, Prop } from "vue-property-decorator";
import { UploadImage } from "../request";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private limitedNumber: any = 0;
  private acceptedType: any = "";
  private get c() {
    // 初始化已有文件
    this.content.setting.forEach((setting: any) => {
      // 限制上传数量
      if (setting.name === "最多上传") {
        this.limitedNumber = setting.value;
      }
      // 限制上传格式
      if (setting.name === "支持格式") {
        const types: any = ["png", "jpeg", "jpg"];
        const arr: any = [];
        types.map((value: any) => {
          arr.push("." + value);
        });
        this.acceptedType = arr.join(",");
      }
    });
    if (!this.content.value) {
      this.content.value = [];
    }
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  /**
   * @description 判断是否为图片需要展示
   */
  private ifPicture(file: any) {
    const arr: any = ["png", "jpeg", "jpg"];
    let exsit = false;
    arr.map((type: any) => {
      if (file.url.indexOf(type) !== -1) {
        exsit = true;
      }
    });
    return exsit;
  }
  /**
   * @description 超出数量时提示
   */
  private exceedHelp() {
    this.$message.warning("该项最多只能上传" + this.limitedNumber + "个文件");
  }
  /**
   * @description 图片上传预处理
   */
  private beforeUpload(file: any) {
    const arr = file.name.split(".");
    const fileType = arr[arr.length - 1];
    if (this.acceptedType.includes(fileType)) {
      return true;
    } else {
      this.$message.warning("只能上传特定类型的文件");
      return false;
    }
  }
  private deleteOne(i: any) {
    const d = JSON.parse(JSON.stringify(this.content));
    d.value.splice(i, 1);
    this.c.value.splice(i, 1);
    if (this.source === "dialog") {
      this.$emit("updateDialog", d);
    } else {
      this.$emit("update", d);
    }
  }
  /**
   * @description 删除文件
   */
  private handleRemove(file: any) {
    let targetIndex: any = "";
    this.c.value.forEach((f: any, index: any) => {
      if (f.url === file.url) {
        targetIndex = index;
      }
    });
    this.c.value.splice(targetIndex, 1);
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  /**
   * @description 上传
   */
  private upload(d: any) {
    const uploadFilePath: any = "ds/file/" + d.file.name;
    const params: any = {
      type: "file",
      base64_image: "",
    };
    const reader = new FileReader();
    reader.readAsDataURL(d.file);
    reader.onload = (e: any) => {
      params["base64_image"] = e.target["result"];
      UploadImage(this, params).then((data: any) => {
        this.c.value.forEach((file: any) => {
          if (file.uid === d.file.uid) {
            file.url = data.image_url;
          }
          delete file.size;
          delete file.status;
          delete file.percentage;
          delete file.raw;
        });
        if (this.source === "dialog") {
          this.$emit("updateDialog", this.c);
        } else {
          this.$emit("update", this.c);
        }
      });
    };
  }
  /**
   * @description 图片预览
   */
  private handlePictureCardPreview(file: any) {
    window.open(file.url);
  }
  /**
   * @description 文件变化时
   */
  private handleFileChange(file: any, fileList: any) {
    this.c.value = fileList;
  }
}
