


































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
import ObjectSelectSearch from "./object-select-search.vue";
@Component({
  components: {
    Title,
    SubTitle,
    ObjectSelectSearch,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private fangshis: any[] = [
    "日一剂，水煎服",
    "日一剂，冲服",
    "日一剂，灌肠",
    "日一剂，外用",
    "日一剂，热敷",
  ];
  private hashKey: any = new Date().getTime();
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  /**
   * @description 删除一个
   */
  private deleteOne(i: any) {
    this.c["value"]["饮片"].splice(i, 1);
    if (this.c["value"]["饮片"].length === 0) {
      this.c["value"]["饮片"].push({});
    }
    this.valueChange();
  }
  /**
   * @description 增加一个
   */
  private addOne() {
    this.c["value"]["饮片"].push({});
    this.valueChange();
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.hashKey = new Date().getTime();
  }
}
