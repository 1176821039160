

























































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private isOpenJianyan: boolean = false;
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange(e: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  /**
   * @description 检验组鼠标失去焦点判断数字偏高还是偏低
   */
  private jianyanBlur(item: any) {
    let result = ""; // 空为不显示图标，low为偏低，high为偏高
    // 如果没有值或者参考值没有则不显示异常图标
    if (!item.value || item.reference.length === 0) {
      return result;
    } else {
      const val = Number(item.value);
      // 判断参考值是一个还是二个
      if (item.reference.length === 1) {
        if (val < item.reference[0]) {
          result = "low";
          return result;
        } else if (val > item.reference[0]) {
          result = "high";
          return result;
        }
      } else if (item.reference.length === 2) {
        if (val < item.reference[0]) {
          result = "low";
          return result;
        } else if (val > item.reference[1]) {
          result = "high";
          return result;
        }
      }
    }
    return result;
  }
}
