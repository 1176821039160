





import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
}
