var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-wrap",class:{ inline: _vm.c.inline }},[(_vm.c.h1_title)?_c('div',{staticClass:"h1_title inTitle"},[_c('pre',[_vm._v(_vm._s(_vm.c.h1_title))])]):_vm._e(),(_vm.c.h2_title)?_c('div',{staticClass:"h2_title inTitle"},[_c('pre',[_vm._v(_vm._s(_vm.c.h2_title))])]):_vm._e(),(_vm.c.title)?_c('Title',{attrs:{"data":_vm.c,"index":_vm.index,"source":_vm.source}}):_vm._e(),(_vm.c.sub_title)?_c('SubTitle',{attrs:{"data":_vm.c,"index":_vm.index,"source":_vm.source}}):_vm._e(),_c('div',{staticClass:"template-content"},_vm._l((_vm.c['options']),function(option,index){return _c('div',{key:index},[_c('el-checkbox',{staticClass:"disabledClass",attrs:{"label":option['name'],"disabled":!_vm.ifCompose && _vm.source !== 'dialog'},on:{"change":function($event){return _vm.clickCheckbox(option)}},model:{value:(option['value']),callback:function ($$v) {_vm.$set(option, 'value', $$v)},expression:"option['value']"}}),(option['type'] == '其它' && option.value)?[(option.content_type === 'number')?_c('el-input',{class:{
            miniSize: option.size === 'mini',
            minSize: option.size === 'min',
            middleSize: option.size === 'middle',
            borderLine: option.border_line,
          },attrs:{"type":"number","disabled":(!_vm.ifCompose || !option.value) && _vm.source !== 'dialog'},on:{"input":_vm.valueChange},model:{value:(option['content']),callback:function ($$v) {_vm.$set(option, 'content', $$v)},expression:"option['content']"}}):_c('el-input',{class:{
            borderLine: option.border_line,
            miniSize: option.size === 'mini',
          },attrs:{"type":"text","disabled":(!_vm.ifCompose || !option.value) && _vm.source !== 'dialog'},on:{"input":_vm.valueChange},model:{value:(option['content']),callback:function ($$v) {_vm.$set(option, 'content', $$v)},expression:"option['content']"}}),(option.unit)?_c('span',{staticClass:"unit",class:{ disabledColor: !_vm.ifCompose && _vm.source !== 'dialog' }},[_vm._v(_vm._s(option.unit))]):_vm._e()]:_vm._e(),(option.value && option.extra)?_c('div',{staticClass:"extra-box"},_vm._l((option.extra),function(item,i){return _c('div',{key:i},[_c('TemplateTransfer',{attrs:{"index":i,"content":item},on:{"update:content":function($event){return _vm.updateContent($event, index)}}})],1)}),0):_vm._e()],2)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }