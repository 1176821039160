

















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private ifShowSelectAll(val: any) {
    // 这是广东民族医特有的,纳排标准——纳入标准——全选是;纳排标准——排除标准——全选否;
    let name: any = "";
    if (this.$store.state.cTemplate) {
      if (this.$store.state.cTemplate["名称"]) {
        name = this.$store.state.cTemplate["名称"];
      } else if (this.$store.state.cTemplate["temp_name"]) {
        name = this.$store.state.cTemplate["temp_name"];
      }
    }
    if (!name) {
      return false;
    }
    const index = name.indexOf("纳排标准");
    if (
      index !== -1 &&
      this.content.title &&
      this.content.title.indexOf(val) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  private selectAll(key: any, val: any) {
    // 在2个段落标题间全选是或者全选否
    const d: any = {
      type: this.content.type,
      key: key,
      val: val,
      index: this.index,
    };
    this.$emit("selectAll", d);
  }
}
