















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
const TemplateTransfer = () => import("../template-transfer.vue");
@Component({
  components: {
    Title,
    SubTitle,
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Watch("content", { immediate: true })
  private contentChange() {
    this.c = JSON.parse(JSON.stringify(this.content));
    this.$forceUpdate();
  }
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private c: any = {};
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange(e: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private updateContent(e: any, index: any) {
    this.c.value[index][e.index] = e.data;
    this.$emit("update", this.c);
  }
  private mounted() {
    this.c = JSON.parse(JSON.stringify(this.content));
    this.$forceUpdate();
  }
}
