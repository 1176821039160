var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-wrap",class:{ inline: _vm.c.inline }},[(_vm.c.h1_title)?_c('div',{staticClass:"h1_title inTitle"},[_c('pre',[_vm._v(_vm._s(_vm.c.h1_title))])]):_vm._e(),(_vm.c.h2_title)?_c('div',{staticClass:"h2_title inTitle"},[_c('pre',[_vm._v(_vm._s(_vm.c.h2_title))])]):_vm._e(),(_vm.c.title)?_c('Title',{attrs:{"data":_vm.c,"index":_vm.index,"source":_vm.source}}):_vm._e(),(_vm.c.sub_title)?_c('SubTitle',{attrs:{"data":_vm.c,"index":_vm.index,"source":_vm.source}}):_vm._e(),_c('div',{staticClass:"template-content"},[(_vm.c.name)?_c('span',{staticClass:"flex-shrink"},[_vm._v(_vm._s(_vm.c.name))]):_vm._e(),(_vm.ifCompose)?_c('el-input',{class:{
        allSize: _vm.c.size == '100%',
        miniSize: _vm.c.size === 'mini',
        minSize: _vm.c.size === 'min',
        middleSize: _vm.c.size === 'middle',
        borderLine: _vm.c.border_line,
      },attrs:{"size":"small","type":"text","placeholder":"","disabled":!_vm.ifCompose && _vm.source !== 'dialog'},on:{"input":_vm.valueChange},model:{value:(_vm.c.value),callback:function ($$v) {_vm.$set(_vm.c, "value", $$v)},expression:"c.value"}}):_c('div',{staticClass:"show-box",class:{
        allSize: _vm.c.size == '100%',
        miniSize: _vm.c.size === 'mini',
        minSize: _vm.c.size === 'min',
        middleSize: _vm.c.size === 'middle',
        borderLine: _vm.c.border_line,
      }},[_vm._v(" "+_vm._s(_vm.c.value)+" ")]),(_vm.c.unit)?_c('span',{staticClass:"flex-shrink"},[_vm._v(_vm._s(_vm.c.unit))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }