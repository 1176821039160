
































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    if (!this.content.value) {
      this.content.value = {};
    }
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private isChecked(item: any) {
    if (this.c.name === item.name) {
      return true;
    } else {
      return false;
    }
  }
  private radioScoreClick(option: any) {
    if (!this.ifCompose && this.source !== "dialog") {
      return;
    }
    if (
      this.c.value &&
      this.c.value.name &&
      this.c.value.name === option.name
    ) {
      this.c.value = {};
    } else {
      this.c.value = option;
    }
    this.valueChange();
  }
}
