var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-wrap",class:{ inline: _vm.c.inline }},[(_vm.c.h1_title)?_c('div',{staticClass:"h1_title inTitle"},[_c('pre',[_vm._v(_vm._s(_vm.c.h1_title))])]):_vm._e(),(_vm.c.h2_title)?_c('div',{staticClass:"h2_title inTitle"},[_c('pre',[_vm._v(_vm._s(_vm.c.h2_title))])]):_vm._e(),(_vm.c.title)?_c('Title',{attrs:{"data":_vm.c,"index":_vm.index,"source":_vm.source}}):_vm._e(),(_vm.c.sub_title)?_c('SubTitle',{attrs:{"data":_vm.c,"index":_vm.index,"source":_vm.source}}):_vm._e(),_c('div',{staticClass:"template-content",class:{ noExtra: !_vm.hasExtra(_vm.c['options']) }},_vm._l((_vm.c['options']),function(option,index){return _c('div',{key:index},[_c('el-radio',{staticClass:"disabledClass",class:{
          noMar: option['type'] == '其它' || option['type'] == '单位',
        },attrs:{"label":true,"disabled":!_vm.ifCompose && _vm.source !== 'dialog'},nativeOn:{"click":function($event){$event.preventDefault();return _vm.clickDanbu(option)}},model:{value:(option.value),callback:function ($$v) {_vm.$set(option, "value", $$v)},expression:"option.value"}},[[_c('span',[_vm._v(_vm._s(option.name))]),(option['提示'])?_c('span',{staticStyle:{"width":"16px","display":"inline-block"}}):_vm._e(),(option['提示'])?_c('i',{staticClass:"el-icon-warning xuanxiangtishi",on:{"click":function($event){return _vm.openTishi(option['提示'])}}}):_vm._e()]],2),(option.value && option['type'] == '其它')?[(option.pre)?_c('span',{class:{ disabledColor: !_vm.ifCompose && _vm.source !== 'dialog' },staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(option.pre))]):_vm._e(),(option.content_type === 'number')?_c('el-input',{class:{
            borderLine: option.border_line,
            miniSize: option.size === 'mini',
          },attrs:{"type":"number","disabled":(!_vm.ifCompose || !option.value) && _vm.source !== 'dialog'},on:{"input":_vm.valueChange},model:{value:(option['content']),callback:function ($$v) {_vm.$set(option, 'content', $$v)},expression:"option['content']"}}):_c('el-input',{class:{
            miniSize: option.size === 'mini',
            minSize: option.size === 'min',
            middleSize: option.size === 'middle',
            borderLine: option.border_line,
          },attrs:{"type":"text","disabled":(!_vm.ifCompose || !option.value) && _vm.source !== 'dialog'},on:{"input":_vm.valueChange},model:{value:(option['content']),callback:function ($$v) {_vm.$set(option, 'content', $$v)},expression:"option['content']"}}),(option.unit)?_c('span',{staticClass:"unit",class:{ disabledColor: !_vm.ifCompose && _vm.source !== 'dialog' }},[_vm._v(_vm._s(option.unit))]):_vm._e()]:_vm._e(),(option.value && option.extra)?[_c('div',{staticClass:"extra-box",class:{ huanhang: option['扩展二级换行'] }},_vm._l((option.extra),function(item,i){return _c('div',{key:i},[_c('TemplateTransfer',{attrs:{"index":i,"content":item},on:{"update:content":function($event){return _vm.updateContent($event, index)}}})],1)}),0)]:_vm._e()],2)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }