











































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private leftPath: any;
  private rightPath: any;
  /**
   * @description 获取store中的可编辑状态
   */
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get c() {
    if (!this.content.value["L左侧"]) {
      this.content.value["L左侧"] = {};
      if (this.source === "dialog") {
        this.$emit("updateDialog", this.content);
      } else {
        this.$emit("update", this.content);
      }
    }
    if (!this.content.value["R右侧"]) {
      this.content.value["R右侧"] = {};
      if (this.source === "dialog") {
        this.$emit("updateDialog", this.content);
      } else {
        this.$emit("update", this.content);
      }
    }
    return JSON.parse(JSON.stringify(this.content));
  }
  /**
   * @description content双向绑定
   */
  private contentChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  /**
   * @description 选择压痛点
   */
  private changeValue(key: any) {
    if (!this.ifCompose && this.source !== "dialog") {
      return;
    }
    if (key === "inside" || key === "outside") {
      this.$set(this.c.value["R右侧"], key, false);
      this.contentChange();
    } else {
      this.$set(this.c.value["L左侧"], key, false);
      this.contentChange();
    }
  }
  private mounted() {
    this.draw();
  }
  private draw() {
    const imgPoint = {
      x: 500 / 2,
      y: 386 / 2,
    };
    const canvasQian: any = document.getElementById("rightQian");
    canvasQian.addEventListener("click", (e: any) => {
      this.clickEvent(e, canvasQian);
    });
    const qianctx = canvasQian.getContext("2d");
    // 画底图
    const qianImg = new Image();
    qianImg.src = "/template/右膝.jpg";
    qianImg.onload = () => {
      qianctx.drawImage(qianImg, 0, 0, 242, 386);
      this.drawLeft(qianctx);
    };
    // 画后面
    const canvasHou: any = document.getElementById("rightHou");
    canvasHou.addEventListener("click", (e: any) => {
      this.clickRightEvent(e, canvasHou);
    });
    const Houctx = canvasHou.getContext("2d");
    // 画底图
    const houImg = new Image();
    houImg.src = "/template/右膝后.jpg";
    houImg.onload = () => {
      Houctx.drawImage(houImg, 0, 0, 242, 386);
      this.drawRight(Houctx);
    };
  }
  private drawLeft(ctx: any) {
    const point = {
      point1: {
        x: 2,
        y: 2,
      },
      point2: {
        x: 32,
        y: 140,
      },
      point3: {
        x: 42,
        y: 220,
      },
      point4: {
        x: 48,
        y: 384,
      },
      point5: {
        x: 226,
        y: 384,
      },
      point6: {
        x: 230,
        y: 222,
      },
      point7: {
        x: 236,
        y: 132,
      },
      point8: {
        x: 222,
        y: 2,
      },
      point9: {
        x: 182,
        y: 2,
      },
      point10: {
        x: 46,
        y: 2,
      },
      point11: {
        x: 92,
        y: 124,
      },
      point12: {
        x: 82,
        y: 172,
      },
      point13: {
        x: 172,
        y: 174,
      },
      point14: {
        x: 162,
        y: 122,
      },
      point15: {
        x: 102,
        y: 245,
      },
      point16: {
        x: 154,
        y: 244,
      },
      point17: {
        x: 114,
        y: 340,
      },
      point18: {
        x: 142,
        y: 340,
      },
      point19: {
        x: 128,
        y: 102,
      },
      point20: {
        x: 128,
        y: 226,
      },
      point21: {
        x: 220,
        y: 300,
      },
      point22: {
        x: 94,
        y: 206,
      },
      point23: {
        x: 162,
        y: 206,
      },
      point24: {
        x: 20,
        y: 60,
      },
      point25: {
        x: 238,
        y: 178,
      },
    };
    this.leftPath = {
      LP: {
        name: "LP",
        path: [
          point.point19,
          point.point11,
          point.point12,
          point.point22,
          point.point20,
          point.point19,
        ],
      },
      MP: {
        name: "MP",
        path: [
          point.point19,
          point.point14,
          point.point13,
          point.point23,
          point.point20,
          point.point19,
        ],
      },
      SL: {
        name: "SL",
        path: [
          point.point1,
          point.point10,
          point.point11,
          point.point12,
          point.point2,
          point.point24,
          point.point1,
        ],
      },
      QT: {
        name: "QT",
        path: [
          point.point10,
          point.point9,
          point.point14,
          point.point19,
          point.point11,
          point.point10,
        ],
      },
      T: {
        name: "T",
        path: [
          point.point3,
          point.point15,
          point.point17,
          point.point18,
          point.point16,
          point.point6,
          point.point21,
          point.point5,
          point.point4,
          point.point3,
        ],
      },
      SM: {
        name: "SM",
        path: [
          point.point9,
          point.point8,
          point.point7,
          point.point13,
          point.point14,
          point.point9,
        ],
      },
      LJLA: {
        name: "LJLA",
        path: [
          point.point2,
          point.point12,
          point.point15,
          point.point3,
          point.point2,
        ],
      },
      MJLA: {
        name: "MJLA",
        path: [
          point.point13,
          point.point7,
          point.point25,
          point.point6,
          point.point16,
          point.point13,
        ],
      },
      PT: {
        name: "PT",
        path: [
          point.point12,
          point.point22,
          point.point20,
          point.point23,
          point.point13,
          point.point16,
          point.point18,
          point.point17,
          point.point15,
          point.point12,
        ],
      },
    };
    // this.showLine(ctx);
    // this.showAllDot(ctx, point);
  }
  private drawRight(ctx: any) {
    const point = {
      point1: {
        x: 97,
        y: 200,
      },
      point2: {
        x: 58,
        y: 290,
      },
      point3: {
        x: 97,
        y: 380,
      },
      point4: {
        x: 136,
        y: 290,
      },
      point5: {
        x: 175,
        y: 200,
      },
      point6: {
        x: 175,
        y: 380,
      },
      point7: {
        x: 214,
        y: 290,
      },
      point8: {
        x: 68,
        y: 240,
      },
      point9: {
        x: 68,
        y: 340,
      },
      point10: {
        x: 124,
        y: 340,
      },
      point11: {
        x: 124,
        y: 240,
      },
      point12: {
        x: 148,
        y: 240,
      },
      point13: {
        x: 148,
        y: 340,
      },
      point14: {
        x: 202,
        y: 340,
      },
      point15: {
        x: 202,
        y: 240,
      },
    };
    this.rightPath = {
      inside: {
        name: "inside",
        path: [
          point.point1,
          point.point8,
          point.point2,
          point.point9,
          point.point3,
          point.point10,
          point.point4,
          point.point11,
          point.point1,
        ],
      },
      outside: {
        name: "outside",
        path: [
          point.point5,
          point.point12,
          point.point4,
          point.point13,
          point.point6,
          point.point14,
          point.point7,
          point.point15,
          point.point5,
        ],
      },
    };
    // 画左边的
    // this.fillIrregularGraphics(ctx, this.rightPath.inside.path, "red");
    // 画右边的
    // this.fillIrregularGraphics(ctx, this.rightPath.outside.path, "red");
  }
  /**
   * 确定左边点击区域
   */
  private clickEvent(e: any, canvas: any) {
    if (!this.ifCompose && this.source !== "dialog") {
      return;
    }
    const point = {
      x: e.clientX - canvas.getBoundingClientRect().x,
      y: e.clientY - canvas.getBoundingClientRect().y,
    };

    const arr = ["LP", "MP", "SL", "QT", "SM", "LJLA", "PT", "MJLA", "T"];
    let part = "";
    for (const key of arr) {
      if (this.judge(point, this.leftPath[key]["path"], 1)) {
        part = this.leftPath[key]["name"];
        this.$set(this.c.value["L左侧"], part, true);
        this.contentChange();
        return;
      }
    }
  }
  /**
   * 确定右边点击区域
   */
  private clickRightEvent(e: any, canvas: any) {
    if (!this.ifCompose && this.source !== "dialog") {
      return;
    }
    const point = {
      x: e.clientX - canvas.getBoundingClientRect().x,
      y: e.clientY - canvas.getBoundingClientRect().y,
    };

    const arr = ["inside", "outside"];
    let part = "";
    for (const key of arr) {
      if (this.judge(point, this.rightPath[key]["path"], 1)) {
        part = this.rightPath[key]["name"];
        this.$set(this.c.value["R右侧"], part, true);
        this.contentChange();
        return;
      }
    }
  }
  /**
   * @param  dot {{x,y}} 需要判断的点
   * @param  coordinates {{x,y}[]} 多边形点坐标的数组，为保证图形能够闭合，起点和终点必须相等。比如三角形需要四个点表示，第一个点和最后一个点必须相同。
   * @param
   */
  private judge(dot: any, coordinates: any, noneZeroMode: any) {
    // 默认启动none zero mode
    noneZeroMode = noneZeroMode || 1;
    const x = dot.x;
    const y = dot.y;
    let crossNum = 0;
    // 点在线段的左侧数目
    let leftCount = 0;
    // 点在线段的右侧数目
    let rightCount = 0;
    for (let i = 0; i < coordinates.length - 1; i++) {
      const start = coordinates[i];
      const end = coordinates[i + 1];

      // 起点、终点斜率不存在的情况
      if (start.x === end.x) {
        // 因为射线向右水平，此处说明不相交
        if (x > start.x) {
          continue;
        }
        // 从左侧贯穿
        if (end.y > start.y && y >= start.y && y <= end.y) {
          leftCount++;
          crossNum++;
        }
        // 从右侧贯穿
        if (end.y < start.y && y >= end.y && y <= start.y) {
          rightCount++;
          crossNum++;
        }
        continue;
      }
      // 斜率存在的情况，计算斜率
      const k = (end.y - start.y) / (end.x - start.x);
      // 交点的x坐标
      const x0 = (y - start.y) / k + start.x;
      // 因为射线向右水平，此处说明不相交
      if (x > x0) {
        continue;
      }

      if (end.x > start.x && x0 >= start.x && x0 <= end.x) {
        crossNum++;
        if (k >= 0) {
          leftCount++;
        } else {
          rightCount++;
        }
      }
      if (end.x < start.x && x0 >= end.x && x0 <= start.x) {
        crossNum++;
        if (k >= 0) {
          rightCount++;
        } else {
          leftCount++;
        }
      }
    }

    return noneZeroMode === 1
      ? leftCount - rightCount !== 0
      : crossNum % 2 === 1;
  }
  /**
   * @description 可以看到左边所有的线
   */
  private showLine(ctx: any) {
    // 画左边的SL
    this.fillIrregularGraphics(ctx, this.leftPath.SL.path, "green");
    // 画右边的SM
    this.fillIrregularGraphics(ctx, this.leftPath.SM.path, "yellow");
    // 画左边的QT
    this.fillIrregularGraphics(ctx, this.leftPath.QT.path, "#d322f1");
    // 画PT
    this.fillIrregularGraphics(ctx, this.leftPath.PT.path, "blue");
    // 画LJLA
    this.fillIrregularGraphics(ctx, this.leftPath.LJLA.path, "pink");
    // 画MJLA
    this.fillIrregularGraphics(ctx, this.leftPath.MJLA.path, "#1ed9e7");
    // 画T
    this.fillIrregularGraphics(ctx, this.leftPath.T.path, "#ef940d");
    // 画LP
    this.fillIrregularGraphics(ctx, this.leftPath.LP.path, "red");
    // 画MP
    this.fillIrregularGraphics(ctx, this.leftPath.MP.path, "orange");
  }
  /**
   * @description 可以看到所有的点
   */
  private showAllDot(ctx: any, point: any) {
    this.drawCircle(ctx, point.point1, 4, "red");
    this.drawCircle(ctx, point.point2, 4, "red");
    this.drawCircle(ctx, point.point3, 4, "red");
    this.drawCircle(ctx, point.point4, 4, "red");
    this.drawCircle(ctx, point.point5, 4, "red");
    this.drawCircle(ctx, point.point6, 4, "red");
    this.drawCircle(ctx, point.point7, 4, "red");
    this.drawCircle(ctx, point.point8, 4, "red");
    this.drawCircle(ctx, point.point9, 4, "red");
    this.drawCircle(ctx, point.point10, 4, "red");
    this.drawCircle(ctx, point.point11, 4, "red");
    this.drawCircle(ctx, point.point12, 4, "red");
    this.drawCircle(ctx, point.point13, 4, "red");
    this.drawCircle(ctx, point.point14, 4, "red");
    this.drawCircle(ctx, point.point15, 4, "red");
    this.drawCircle(ctx, point.point16, 4, "red");
    this.drawCircle(ctx, point.point17, 4, "red");
    this.drawCircle(ctx, point.point18, 4, "red");
    this.drawCircle(ctx, point.point19, 4, "red");
    this.drawCircle(ctx, point.point20, 4, "red");
    this.drawCircle(ctx, point.point21, 4, "red");
    this.drawCircle(ctx, point.point22, 4, "red");
    this.drawCircle(ctx, point.point23, 4, "red");
    this.drawCircle(ctx, point.point24, 4, "red");
    this.drawCircle(ctx, point.point25, 4, "red");
  }
  /**
   * 画线(实线或虚线)
   * start 开始坐标{x,y}
   * end 结束坐标{x,y}
   * color 线条颜色
   * lineWidth 线条宽度
   * lineStyle 实线或者虚线
   * dash 虚线间隔
   */
  private drawDashLine(
    ctx: any,
    start: any,
    end: any,
    color: any,
    lineWidth = 0.5
  ) {
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;

    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }
  /**
   * 画不规则图形
   * @param {*} ctx
   * @param {*} points 所有点的数组
   * @param {*} fillColor 填充颜色
   * @param {*} globalAlpha 透明度
   */
  private fillIrregularGraphics(
    ctx: any,
    points: any,
    color: any,
    globalAlpha = 1
  ) {
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.globalAlpha = globalAlpha;
    for (let i = 0; i < points.length; i++) {
      if (i === 0) {
        ctx.moveTo(points[i].x, points[i].y);
      } else {
        ctx.lineTo(points[i].x, points[i].y);
      }
    }
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }
  /**
   * 画图片
   * path 图片路径
   * point 旋转的中心坐标,即图片的中心坐标
   * width 图片的宽
   * height 图片的高
   * angleNum 旋转的角度
   */
  private drawImage(
    ctx: any,
    path: any,
    point: any,
    width: any,
    height: any,
    angleNum = 0
  ) {
    ctx.save();
    ctx.translate(point.x, point.y);
    ctx.rotate(angleNum);
    ctx.translate(-point.x, -point.y);
    // 画图片
    ctx.drawImage(
      path,
      point.x - width / 2,
      point.y - height / 2,
      width,
      height
    );
    ctx.restore();
  }
  /**
   * 画圆
   * center 圆心坐标
   * radius 半径
   * style.color 线条颜色
   * style.strokeWidth 线条宽度
   * style.fillColor 填充颜色
   * style.dash 虚线度 []
   */
  private drawCircle(ctx: any, center: any, radius: any, color: any) {
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.arc(center.x, center.y, radius, 0, Math.PI * 2);
    ctx.strokeStyle = color;
    ctx.stroke();
  }
}
