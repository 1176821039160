








































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetTerms } from "../request";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private resultOptions: any[] = [];
  private get c() {
    this.$nextTick().then(() => {
      this.content.value.forEach((ele: any) => {
        const index = this.resultOptions.findIndex((val: any) => {
          return ele.id === val.id;
        });
        if (index === -1) {
          this.resultOptions.push(ele);
        }
      });
    });
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange(e: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  /**
   * @description 搜索
   */
  private fetchOptions(ev: any) {
    const params: any = {
      params: {
        type: this.content["search"],
        search: ev,
      },
    };
    GetTerms(this, params).then((data: any) => {
      this.content.value.forEach((element: any) => {
        const index = data.findIndex((val: any) => {
          return element.id === val.id;
        });
        if (index === -1) {
          data.push(element);
        }
      });
      this.resultOptions = data;
    });
  }
}
