














































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
import { PostJibing } from "../request";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private shifou: any = ["是", "否"];
  private zhiliao: any = [
    "未治疗",
    "间断治疗",
    "坚持治疗",
    "手术治疗",
    "物理康复治疗",
  ];
  private jibingzhuangui: any = ["治愈", "好转", "稳定", "恶化", "不清楚"];
  private jibing: any = [];
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange(e?: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
    this.$forceUpdate();
  }
  private addOne() {
    const obj = {
      疾病: "",
      是否患病: "",
      确诊时间: "",
      治疗情况: "",
      服用药物: "",
      疾病转归: "",
      是否为主要困扰您健康的疾病: "",
    };
    this.c.value.push(obj);
    this.valueChange();
  }
  private delOne(index: any) {
    this.c.value.splice(index, 1);
    this.valueChange();
  }
  private radioClick(ev: any, index: any, type: any) {
    if (!this.ifCompose) {
      return;
    }
    this.c.value[index][type] === ev
      ? (this.c.value[index][type] = "")
      : (this.c.value[index][type] = ev);
    this.valueChange();
  }
  mounted() {
    const params = {
      类别: this.c.title,
    };
    PostJibing(this, params).then((res: any) => {
      this.jibing = res;
    });
  }
}
