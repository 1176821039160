









































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  EditPatient,
  PatientDetail,
  GetRenkouxueTemplate,
  GetCaozuoTemplate,
  GetRenkouxueContent,
  ChangeRenkouxueContent,
  GetCaozuoTemplateContent,
} from "@/request/storehouse";
import { hideContent } from "@/utils/base";
import { getTopicOpt } from "@/request/topic";
import TemplateTransfer from "@/components/template-transfer-pc/template-transfer/template-transfer.vue";
import Agree from "../agree/agree.vue";
@Component({
  components: {
    TemplateTransfer,
    Agree,
  },
})
export default class Name extends Vue {
  @Prop()
  private ifShow: any;
  @Prop()
  private data: any;
  @Prop()
  private type: any;
  private ifShowAgree: any = false;
  private topicOpt: any = [];
  private templates: any[] = [];
  private patient: any = {};
  private get center() {
    return this.$store.state.center;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get visible() {
    return this.ifShow;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private set visible(val) {
    this.$store.commit("updateIfCompose", false);
    this.$emit("cancel", val);
  }
  private openAgree() {
    if (this.configuration.agreePage) {
      this.ifShowAgree = true;
    }
  }
  private onShenfenzhengBlur(e: any) {
    if (this.patient["出生日期"]) {
      return;
    }
    const strId = this.patient["身份证号"].toString();
    if (strId.length !== 18) {
      return;
    }
    const year = Number(strId.substr(6, 4));
    let month: any = Number(strId.substr(10, 2));
    let day: any = Number(strId.substr(12, 2));
    // if (year === NaN || month === NaN || day === NaN) {
    //   return;
    // }
    if (year < 1900 || year > 2200) {
      return;
    }
    if (month < 1 || month > 12) {
      return;
    }
    if (day < 1 || day > 31) {
      return;
    }
    if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) == -1) {
      if (day > 30) {
        return;
      }
    }
    if (year % 4 !== 0 && month === 2 && day > 28) {
      return;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    this.patient["出生日期"] = "" + year + "-" + month + "-" + day;
  }
  private cancel() {
    this.visible = false;
    this.$emit("cancel", false);
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private updateWholeTem(e: any, index: any) {
    this.templates[index]["contents"]["data"] = e;
    this.$forceUpdate();
  }
  private updateContent(e: any, index: any) {
    this.templates[index]["contents"]["data"][e.index] = e.data;
    this.$forceUpdate();
  }
  /**
   * @description 获取患者信息
   */
  private getPatient() {
    const params: any = {
      params: {
        project_id: this.data.project_id,
        group_id: this.data.group_id,
        patient_id: this.data.patient_id,
      },
    };
    PatientDetail(this, params).then((res: any) => {
      this.patient = res;
      if (this.type === "基本信息") {
        this.getRenkuoxueTemplate();
      } else {
        this.getCaozuoTemplate();
      }
    });
  }
  /**
   * @description 隐藏或者显示某些相关题目
   */
  private changeHide(e: any, index: any) {
    const data = this.templates[index]["contents"].data;
    hideContent(e, data);
  }
  /**
   * @description 获取人口学信息
   */
  private getRenkuoxueTemplate() {
    this.templates = [];
    const params: any = {
      params: {
        project_id: this.patient.project_id,
        group_id: this.patient.group_id,
      },
    };
    GetRenkouxueTemplate(this, params).then((templates: any) => {
      this.templates = templates;
      this.templates.forEach((template: any, index: any) => {
        const params2: any = {
          params: {
            project_id: template["project_id"],
            group_id: template["group_id"],
            patient_id: this.patient["patient_id"],
            type: template["名称"],
          },
        };
        GetRenkouxueContent(this, params2).then((contents: any) => {
          this.$set(this.templates[index], "contents", contents);
        });
      });
    });
  }
  /**
   * @description 获取操作模板
   */
  private getCaozuoTemplate() {
    this.templates = [];
    const params: any = {
      params: {
        project_id: this.patient.project_id,
      },
    };
    GetCaozuoTemplate(this, params).then((res: any) => {
      let d: any = [];
      res.forEach((element: any) => {
        if (element["名称"] == this.type) {
          d.push(element);
        }
      });
      this.templates = d;
      this.templates.forEach((template: any, index: any) => {
        const params2: any = {
          params: {
            project_id: template["project_id"],
            patient_id: this.patient["patient_id"],
            type: template["名称"],
          },
        };
        GetCaozuoTemplateContent(this, params2).then((contents: any) => {
          this.$set(this.templates[index], "contents", contents);
        });
      });
    });
  }
  /**
   * @description 保存
   */
  private save() {
    //上传人口学信息
    this.templates.forEach((template: any) => {
      const params: any = {
        project_id: template["project_id"],
        group_id: template["group_id"],
        create_time: template["create_time"],
        template_id: template["template_id"],
        patient_id: this.patient["patient_id"],
      };
      params["data"] = template["contents"]["data"];
      params["content_id"] = template["contents"]["content_id"];
      ChangeRenkouxueContent(this, params).then((d: any) => {});
    });
    if (this.type !== "基本信息") {
      this.$message.success("保存成功！");
      this.$store.commit("updateIfCompose", false);
      this.cancel();
      return;
    }
    if (!this.patient["姓名"]) {
      this.$message.warning(this.configuration.nameText + "必须填写");
      return;
    }
    if (this.configuration.name == "shuguang" && !this.patient["select"]) {
      this.$message.warning("请同意签署知情同意书");
      return;
    }
    const birthdayRep: RegExp = /\d{4}-[0-1]\d-\d{2}/gi;
    if (this.patient["出生日期"]) {
      if (!birthdayRep.test(this.patient["出生日期"])) {
        this.$message.warning("出生日期格式不对，请重新输入");
        return;
      }
    }

    if (!this.patient["身份证号"]) {
      this.$message.warning("认证号必须填写");
      return;
    }
    // 验证身份证格式
    // const shenfenzhengRep: RegExp = /^[a-zA-Z0-9]{18}$/;
    // if (!shenfenzhengRep.test(this.patient["身份证号"])) {
    //   this.$message.warning("认证号码格式错误，请输入18位数字或字母！");
    //   return;
    // }
    // 验证联系方式
    const shoujiRep: RegExp = /^\d{11}$/;
    if (this.patient["认证方式"] === "联系方式") {
      if (!shoujiRep.test(this.patient["身份证号"])) {
        this.$message.warning("认证号码格式错误，请输入11位数！");
        return;
      }
    }
    this.patient.group_id = this.center.group_id;
    this.patient.project_id = this.center.project_id;
    const obj: any = JSON.parse(JSON.stringify(this.patient));
    obj["身份证号"] = this.patient.card;
    EditPatient(this, obj).then((data: any) => {
      this.$message.success("保存成功！");
      this.$store.commit("updatePatient", data);
      this.$store.commit("updateIfCompose", false);
      this.$emit("change");
      this.cancel();
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.getPatient();
    getTopicOpt(this).then((res: any) => {
      this.topicOpt = res;
    });
  }
}
