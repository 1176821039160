import { render, staticRenderFns } from "./yaozhui-yatongdian-left.vue?vue&type=template&id=123900f2&scoped=true&"
import script from "./yaozhui-yatongdian-left.vue?vue&type=script&lang=ts&"
export * from "./yaozhui-yatongdian-left.vue?vue&type=script&lang=ts&"
import style0 from "./yaozhui-yatongdian-left.vue?vue&type=style&index=0&id=123900f2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123900f2",
  null
  
)

export default component.exports