











import { Component, Vue, Prop } from "vue-property-decorator";
import CommonPatient from "./common-patient.vue";
@Component({
  components: {
    CommonPatient,
  },
})
export default class Name extends Vue {
  @Prop()
  private ifShow: any;
  @Prop()
  private patient: any;
  @Prop({ type: String, default: "基本信息" })
  private type: any;
  private get configuration() {
    return this.$store.state.configuration;
  }
  private cancel() {
    this.$emit("cancel", false);
  }
  private change() {
    this.$emit("change");
  }
}
