











import { Component, Vue, Prop } from "vue-property-decorator";
import Status from "../status.vue";
const TemplateTransfer = () => import("../template-transfer.vue");
@Component({
  components: {
    Status,
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    return JSON.parse(JSON.stringify(this.data));
  }
}
