









































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange(e: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private delTimu() {
    this.$emit("delTimu", this.index);
  }
  private change() {
    if (!this.c.value) {
      return;
    }
    if (this.c.max && this.c.value > this.c.max) {
      if (this.c.maxTip) {
        this.$message.warning(this.c.maxTip);
      } else {
        this.$message.warning("数值不能大于" + this.c.max);
      }
      // 如果限制输入，需要清除当前的
      if (this.c.limit) {
        this.c.value = null;
        this.$forceUpdate();
        this.valueChange(this.c.value);
      }
      return;
    }
    if (this.c.min && this.c.value < this.c.min) {
      if (this.c.minTip) {
        this.$message.warning(this.c.minTip);
      } else {
        this.$message.warning("数值不能小于" + this.c.max);
      }
      // 如果限制输入，需要清除当前的
      if (this.c.limit) {
        this.c.value = null;
        this.$forceUpdate();
        this.valueChange(this.c.value);
      }
      return;
    }
    if (this.c.precision || this.c.precision === 0) {
      const numStr = this.c.value.toString();
      const decimalPart = numStr.split(".")[1];
      const decimalLength = decimalPart ? decimalPart.length : 0;
      if (decimalLength !== this.c.precision) {
        if (this.c.precision === 0) {
          this.$message.warning("请输入整数");
        } else {
          this.$message.warning("请保留" + this.c.precision + "位小数");
        }
        // 如果限制输入，需要清除当前的
        if (this.c.limit) {
          this.c.value = null;
          this.$forceUpdate();
          this.valueChange(this.c.value);
        }
      }
    }
  }
}
