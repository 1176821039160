import { render, staticRenderFns } from "./template-transfer.vue?vue&type=template&id=3b6c772f&scoped=true&"
import script from "./template-transfer.vue?vue&type=script&lang=ts&"
export * from "./template-transfer.vue?vue&type=script&lang=ts&"
import style0 from "./template-transfer.vue?vue&type=style&index=0&id=3b6c772f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6c772f",
  null
  
)

export default component.exports