

















import { Component, Vue, Prop } from "vue-property-decorator";
import Status from "../status.vue";
const TemplateTransfer = () => import("../template-transfer.vue");
@Component({
  components: {
    Status,
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    return JSON.parse(JSON.stringify(this.data));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private ifShowJishu() {
    // 这是广东民族医特有的
    if (!this.$store.state.cTemplate || !this.$store.state.cTemplate["名称"]) {
      return false;
    }
    const index = this.$store.state.cTemplate["名称"].indexOf("症状评价");
    if (
      index !== -1 &&
      this.data.type == "固定单位数字" &&
      (this.data.title == "压痛关节数" || this.data.title == "肿胀关节数")
    ) {
      return true;
    } else {
      return false;
    }
  }
  private jishu() {
    // 这是广东民族医特有的
    this.$emit("jishu");
  }
}
