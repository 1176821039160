






























































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class DetailContentYatongdian extends Vue {
  private cityOption: any;
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private leftText: any = [];
  private rightText: any = [];
  /**
   * @description 获取store中的可编辑状态
   */
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get c() {
    return this.content;
  }
  private set c(val) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", val);
    } else {
      this.$emit("update", val);
    }
  }
  /**
   * @description content双向绑定
   */
  private contentChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.content);
    } else {
      this.$emit("update", this.content);
    }
  }
  private toggleLeftValue(key: any) {
    if (key == "无压痛点") {
      // 清空其他选项
      const value = !this.c.value[key];
       this.c.value = {};
      this.$set(this.c.value, key, value);
      this.getText();
    } else {
      const value = !this.c.value[key];
      this.$set(this.c.value, key, value);
      this.$set(this.c.value, "无压痛点", false);
      this.getText();
    }
  }
  private toggleRightValue(key: any) {
    if (key == "无压痛点") {
      // 清空其他选项
      const value = !this.c.value[key];
      this.c.value = {};
      this.$set(this.c.value, key, value);
      this.getText();
    } else {
      const value = !this.c.value[key];
      this.$set(this.c.value, key, value);
      this.$set(this.c.value, "无压痛点", false);
      this.getText();
    }
  }
  private getText() {
    let keyArr: any = [];
    let TextArr: any = [];
    if (this.c.title === "背面压痛点") {
      keyArr = [
        "上顶线",
        "寰枕间隙",
        "寰枢间隙",
        "C2-3",
        "C3-4",
        "C4-5",
        "C5-6",
        "C6-7",
        "C7",
        "T1",
        "T2",
        "T3",
        "内上角",
        "内缘",
        "外缘",
      ];
      TextArr = [
        "上顶线(枕外隆凸两侧)",
        "寰枕间隙",
        "寰枢间隙",
        "关节间隙C2-3",
        "关节间隙C3-4",
        "关节间隙C4-5",
        "关节间隙C5-6",
        "关节间隙C6-7",
        "棘突侧方C7",
        "棘突侧方T1",
        "棘突侧方T2",
        "棘突侧方T3",
        "内上角(肩胛骨)",
        "内缘(肩胛骨)",
        "外缘(肩胛骨)",
      ];
    } else if (this.c.title === "正面压痛点") {
      keyArr = ["锁骨上窝", "胸锁乳突肌锁骨附着处", "气户", "喙突"];
      TextArr = ["锁骨上窝", "胸锁乳突肌锁骨附着处", "气户", "喙突"];
    } else if (this.c.title === "侧面压痛点") {
      keyArr = [
        "颞骨乳突",
        "乳突后下方",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "颈横纹",
      ];
      TextArr = [
        "颞骨乳突",
        "乳突后下方",
        "横突1",
        "横突2",
        "横突3",
        "横突4",
        "横突5",
        "横突6",
        "横突7",
        "颈横纹",
      ];
    }
    this.leftText = [];
    this.rightText = [];
    keyArr.forEach((ele: any, i: any) => {
      if (this.c.value[ele]) {
        this.leftText.push(TextArr[i]);
      }
    });
    keyArr.forEach((ele: any, i: any) => {
      if (this.c.value[ele]) {
        this.rightText.push(TextArr[i]);
      }
    });
  }
  /**
   * @description 初始化
   */
  private beforeMount() {
    this.getText();
  }
}
