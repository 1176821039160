






































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
 private jibing: any = ["治愈", "好转", "稳定", "恶化", "不清楚"];
  private get c() {
    const arr = this.content;
    if (!arr.value || arr.value.length === 0) {
      const obj = {
        入院诊断: "",
        加重原因: "",
        住院天数: "",
        疾病转归: "",
      };
      arr.value = [obj];
    }
    this.$forceUpdate();
    return arr;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange(e?: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private radioClick(ev: any, index: any) {
    if (!this.ifCompose) {
      return;
    }
    this.c.value[index]["疾病转归"] === ev
      ? (this.c.value[index]["疾病转归"] = "")
      : (this.c.value[index]["疾病转归"] = ev);
    this.$emit("update", this.c);
  }
  private addOne() {
    if (!this.c.value) {
      this.c.value = [];
    }
    const obj = {
      入院诊断: "",
      加重原因: "",
      住院天数: "",
      疾病转归: "",
    };
    this.c.value.push(obj);
    this.valueChange();
  }
  private delOne(index: any) {
    this.c.value.splice(index, 1);
    this.valueChange();
  }
}
